@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.btn,
.jumbotron,
.nav :hover {
     border-radius: 0px;
}

body {
     /* background: #343a40; */
     /* font-family: "Roboto",sans-serif; */
     font-family: "Quicksand", sans-serif !important;
     font-weight: 300;
     line-height: 1.5;
     background-image: url("https://www.transparenttextures.com/patterns/cubes.png");
     background-color: #f8f9fa;
}

/* loading */

.custom-loading ul {
       /* position: absolute;
       top: 30%;
       left: 50%; */
       transform: translate(-50%, 50%);
       margin: 0;
       padding: 0;
       display: flex
}
   
.custom-loading ul li {
       list-style: none;
       width: 20px;
       height: 20px;
       background: #6351ce;
       margin: 0 5px;
       border-radius: 50%;
       animation: animate 1.4s linear infinite
}
   
@keyframes animate {
       0% {
           transform: translateY(0)
       }
   
       60% {
           transform: translateY(0)
       }
   
       80% {
           transform: translateY(-20px)
       }
   
       100% {
           transform: translateY(0px)
       }
}
   
.custom-loading ul li:nth-child(1) {
       animation-delay: -1.2s
}
   
.custom-loading ul li:nth-child(2) {
       animation-delay: -1.0s
}
   
.custom-loading ul li:nth-child(3) {
       animation-delay: -.8s
}
   
.custom-loading ul li:nth-child(4) {
       animation-delay: -0.6s
}

/* About Us CSS */

.social-link {
       width: 30px;
       height: 30px;
       border: 1px solid #ddd;
       display: flex;
       align-items: center;
       justify-content: center;
       color: #666;
       border-radius: 50%;
       transition: all 0.3s;
       font-size: 0.9rem;
}
     
.social-link:hover,
.social-link:focus {
       background: #ddd;
       text-decoration: none;
       color: #555;
}

/* Home Page Custom CSS */

/* header */

#title {
     background-color: #6351ce !important;
}

#title-text {
     font-family: "Montserrat", sans-serif !important;
     font-size: 4rem !important;
     line-height: 1.5 !important;
     color: white !important;
     margin-top: 150px !important;
}

.header-container {
     padding: 3% 15%;
}

.iphone {
     text-align: center;
     margin-top: 100px;
     /* transform: rotate(25deg); */
     position: absolute;
     right: 10%;
}

.download-btn {
     margin: 30px 10px 100px 0px;
}

/* features */

#features {
     text-align: center;
     padding: 3% 15%;
     background-color: white;
     position: relative;
     z-index: 1;
     background-color: #f2f2f2;
}

.feature-box {
     padding: 7%;
}

.feature-box h3 {
     font-family: "Montserrat", sans-serif !important;
     font-size: 1.5rem !important;
}

.feature-box p {
     color: #8f8f8f;
}

.feature-box .icon {
     color: #6351ce;
     margin-bottom: 1rem;
}

.feature-box .icon:hover {
     color: #6351ce;
}

/* testimonial */

#testimonials {
     background-color: #6351ce;
     color: white;
     text-align: center;
}

#testimonials .carousel-inner {
     padding: 7% 15%;
}

#testimonials .testimonial-image {
     border-radius: 50%;
     width: 10%;
     margin: 25px 20px;
}

#testimonials .testimonial-title {
     font-family: "Ubuntu", "Montserrat", sans-serif;
     font-size: 3rem;
}

/* press */

#press {
     background-color: #6351ce;
     text-align: center;
     padding-bottom: 7%;
}

#press .press-img {
     padding: 0px 30px 0px;
}

/* cta */

#cta {
     padding: 7% 15% 1%;
     text-align: center;
     background-color: #6351ce;
}

.cta-text {
     font-family: "Montserrat", sans-serif;
     font-size: 3rem;
     color: white;
}

@media (max-width: 1028px) {
     #title-img {
          position: static;
          transform: rotate(0);
          width: 0%;
     }
     #title {
          text-align: center;
     }
     .testimonial-title {
          font-size: 2rem;
     }
     .press-img {
          width: 40%;
     }
}

/* CSS from pcparts */

.btn-purple {
     background-color: #6351ce;
     color: #fff;
}

.btn-purple:hover {
     background-color: #3b2b94;
     color: #fff;
}

.btn-off {
     color: #6351ce;
     border-color: #6351ce;
}

.btn-off:hover {
     color: #fff;
     background-color: #6351ce;
     border-color: #6351ce;
}

.theme-color {
     color: #6351ce;
}

.dark-bg {
     background-color: #1c2331;
}

.theme-bg {
     background-color: #6351ce;
}

.active-menu-style {
     color: #6351ce !important;
     background-color: #ffffff;
     border-radius: 25px;
     font-weight: 700;
}

/* footer CSS */

/* footer {
  position:absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
} */

footer.page-footer {
     bottom: 0;
     color: #fff;
}

footer.page-footer .container-fluid {
     width: auto;
}

footer.page-footer .footer-copyright {
     overflow: hidden;
     color: rgba(255, 255, 255, 0.6);
     background-color: rgba(0, 0, 0, 0.2);
}

footer.page-footer a {
     color: #fff;
}

.font-small {
     font-size: 0.9rem;
}

.unique-color-dark {
     background-color: #1c2331 !important;
}

.fb-ic:hover,
.fb-ic:focus {
     color: #3b5998;
}

.tw-ic:hover,
.tw-ic:focus {
     color: #55acee;
}

.gplus-ic:hover,
.gplus-ic:focus {
     color: #dd4b39;
}

.li-ic:hover,
.li-ic:focus {
     color: #0082ca;
}

.ins-ic:hover,
.ins-ic:focus {
     color: #2e5e86;
}

.deep-purple {
     background-color: #512da8;
}

.deep-purple.accent-2 {
     background-color: #7c4dff !important;
}

hr {
     border-top-color: rgba(0, 0, 0, 0.1);
     border-top-style: solid;
     border-top-width: 1px;
     box-sizing: content-box;
     height: 0;
     overflow: visible;
     overflow-x: visible;
     overflow-y: visible;
}

a {
     text-decoration: none;
}

white-text {
     color: #ffffff;
}

/* register/login page css */

input[type="checkbox"]:checked {
     background-color: #6351ce !important;
     border: 2px solid #6351ce !important;
}

.ph-gray {
     color: #808080;
}

.btn-login {
     font-size: 0.9rem;
     letter-spacing: 0.05rem;
     padding: 0.75rem 1rem;
}

.btn-google {
     color: white !important;
     background-color: #ea4335;
}

.btn-google:hover {
     background-color: #a5190d;
}

.btn-facebook {
     color: white !important;
     background-color: #3b5998;
}

.btn-facebook:hover {
     background-color: #0d2c6d;
}

.nav-link:hover {
     cursor: pointer;
}

/* admin panel */

.admin-option:hover {
     color: #fff !important;
     background-color: #6351ce;
     border-radius: 5px;
}

.admin-option {
     background-color: #f8f9fa;
     border-radius: 5px;
}

/* Product page */

.color-grey-light {
     background-color: #fbfbfb !important;
}

.bg-gray {
     background-color: lightgray;
}

/*user dashboard page */

.github-repos {
     background-color: #ddd;
     border-radius: 30px;
}

.github-repos .repo {
     background-color: #fff;
     box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
     border-radius: 30px;
}

.github-repos .repo h5 {
     font-weight: 600;
}

.github-repos .repo ul {
     list-style: none;
     text-align: center;
}

.github-repos .repo ul li:first-child {
     background-color: #603cc9;
     color: #fff;
     border-radius: 30px;
     padding: 8px 15px;
     margin: 0;
}

.github-repos .repo ul li {
     background-color: #000;
     color: #fff;
     border-radius: 30px;
     padding: 8px 15px;
     margin: 1em auto;
}

.github-repos .repo ul li:last-child {
     background-color: #3880ff;
     color: #fff;
     border-radius: 30px;
     padding: 8px 15px;
     margin: 0;
}

.skills-section ul {
     display: flex;
     padding: 0;
     list-style: none;
     align-items: center;
}

.skills-section ul li {
     background-color: #ddd;
     border-radius: 30px;
     padding: 8px 20px;
     font-weight: 600;
}

.bio-chart-input {
     width: 54px;
     height: 33px;
     position: absolute;
     vertical-align: middle;
     margin-top: 33px;
     margin-left: -77px;
     border: 0px;
     font-weight: bold;
     font-style: normal;
     font-variant: normal;
     font-stretch: normal;
     font-size: 20px;
     line-height: normal;
     font-family: Arial;
     text-align: center;
     color: rgb(203, 164, 219);
     padding: 0px;
     -webkit-appearance: none;
     background: none;
}

.profile-nav,
.profile-info {
     margin-top: 30px;
}

.profile-nav .user-heading {
     background: #6351ce;
     color: #fff;
     padding: 30px;
     text-align: center;
}

.profile-nav .user-heading.round a {
     border-radius: 50%;
     -webkit-border-radius: 50%;
     border: 10px solid rgba(255, 255, 255, 0.3);
     display: inline-block;
}

.profile-nav .user-heading a img {
     width: 112px;
     height: 112px;
     border-radius: 50%;
     -webkit-border-radius: 50%;
}

.profile-nav .user-heading h1 {
     font-size: 22px;
     font-weight: 300;
     margin-bottom: 5px;
}

.profile-nav .user-heading p {
     font-size: 12px;
}

.r-activity {
     margin: 6px 0 0;
     font-size: 12px;
}

.p-text-area,
.p-text-area:focus {
     border: none;
     font-weight: 300;
     box-shadow: none;
     color: #c3c3c3;
     font-size: 16px;
}

.profile-info .panel-footer {
     background-color: #f8f7f5;
     border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
     color: #7a7a7a;
}

.bio-graph-heading {
     background: #fbc02d;
     color: #fff;
     text-align: center;
     font-style: italic;
     padding: 40px 110px;
     border-radius: 4px 4px 0 0;
     -webkit-border-radius: 4px 4px 0 0;
     font-size: 16px;
     font-weight: 300;
}

.bio-graph-info {
     color: #89817e;
}

.bio-graph-info h1 {
     font-size: 22px;
     font-weight: 300;
     margin: 0 0 20px;
}

.bio-row {
     width: 50%;
     float: left;
     margin-bottom: 10px;
     padding: 0 15px;
}

.bio-row p span {
     width: 100px;
     display: inline-block;
}

.bio-chart,
.bio-desk {
     float: left;
}

.bio-chart {
     width: 40%;
}

.bio-desk {
     width: 60%;
}

.bio-desk h4 {
     font-size: 15px;
     font-weight: 400;
}

.bio-desk h4.terques {
     color: #4cc5cd;
}

.bio-desk h4.red {
     color: #e26b7f;
}

.bio-desk h4.green {
     color: #97be4b;
}

.bio-desk h4.purple {
     color: #caa3da;
}

.file-pos {
     margin: 6px 0 10px 0;
}

.profile-activity h5 {
     font-weight: 300;
     margin-top: 0;
     color: #c3c3c3;
}

.summary-head {
     background: #ee7272;
     color: #fff;
     text-align: center;
     border-bottom: 1px solid #ee7272;
}

.summary-head h4 {
     font-weight: 300;
     text-transform: uppercase;
     margin-bottom: 5px;
}

.summary-head p {
     color: rgba(255, 255, 255, 0.6);
}

ul.summary-list {
     display: inline-block;
     padding-left: 0;
     width: 100%;
     margin-bottom: 0;
}

ul.summary-list > li {
     display: inline-block;
     width: 19.5%;
     text-align: center;
}

ul.summary-list > li > a > i {
     display: block;
     font-size: 18px;
     padding-bottom: 5px;
}

ul.summary-list > li > a {
     padding: 10px 0;
     display: inline-block;
     color: #818181;
}

ul.summary-list > li {
     border-right: 1px solid #eaeaea;
}

ul.summary-list > li:last-child {
     border-right: none;
}

.activity {
     width: 100%;
     float: left;
     margin-bottom: 10px;
}

.activity.alt {
     width: 100%;
     float: right;
     margin-bottom: 10px;
}

.activity span {
     float: left;
}

.activity.alt span {
     float: right;
}
.activity span,
.activity.alt span {
     width: 45px;
     height: 45px;
     line-height: 45px;
     border-radius: 50%;
     -webkit-border-radius: 50%;
     background: #eee;
     text-align: center;
     color: #fff;
     font-size: 16px;
}

.activity.terques span {
     background: #8dd7d6;
}

.activity.terques h4 {
     color: #8dd7d6;
}
.activity.purple span {
     background: #b984dc;
}

.activity.purple h4 {
     color: #b984dc;
}
.activity.blue span {
     background: #90b4e6;
}

.activity.blue h4 {
     color: #90b4e6;
}
.activity.green span {
     background: #aec785;
}

.activity.green h4 {
     color: #aec785;
}

.activity h4 {
     margin-top: 0;
     font-size: 16px;
}

.activity p {
     margin-bottom: 0;
     font-size: 13px;
}

.activity .activity-desk i,
.activity.alt .activity-desk i {
     float: left;
     font-size: 18px;
     margin-right: 10px;
     color: #bebebe;
}

.activity .activity-desk {
     margin-left: 70px;
     position: relative;
}

.activity.alt .activity-desk {
     margin-right: 70px;
     position: relative;
}

.activity.alt .activity-desk .panel {
     float: right;
     position: relative;
}

.activity-desk .panel {
     background: #f4f4f4;
     display: inline-block;
}

.activity .activity-desk .arrow {
     border-right: 8px solid #f4f4f4 !important;
}
.activity .activity-desk .arrow {
     border-bottom: 8px solid transparent;
     border-top: 8px solid transparent;
     display: block;
     height: 0;
     left: -7px;
     position: absolute;
     top: 13px;
     width: 0;
}

.activity-desk .arrow-alt {
     border-left: 8px solid #f4f4f4 !important;
}

.activity-desk .arrow-alt {
     border-bottom: 8px solid transparent;
     border-top: 8px solid transparent;
     display: block;
     height: 0;
     right: -7px;
     position: absolute;
     top: 13px;
     width: 0;
}

.activity-desk .album {
     display: inline-block;
     margin-top: 10px;
}

.activity-desk .album a {
     margin-right: 10px;
}

.activity-desk .album a:last-child {
     margin-right: 0px;
}

/* extra css */

::-moz-selection {
     /* Code for Firefox */
     color: #fff;
     background: #6351ce;
}

::selection {
     color: #fff;
     background: #6351ce;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
}

/* Firefox */
input[type="number"] {
     -moz-appearance: textfield;
}

/* media query css */

@media (min-width: 600px) {
     .fixed-sn main,
     .fixed-sn .page-footer .container-fluid {
          margin-right: 2%;
          margin-left: 2%;
     }
}

@media (min-width: 992px) {
     .fixed-sn main,
     .fixed-sn .page-footer .container-fluid {
          margin-right: 5%;
          margin-left: 5%;
     }
}

@media (min-width: 1200px) {
     .fixed-sn main,
     .fixed-sn .page-footer .container-fluid {
          margin-right: 6%;
          margin-left: 6%;
     }
}

@media (max-width: 992px) {
     .navbar .container {
          width: 100%;
     }

     footer {
          text-align: center;
     }

     .navbar .container .navbar-toggler-right {
          right: 0;
     }
}

@media (max-width: 768px) {
     .double-nav .container {
          padding-right: 0;
          padding-left: 0;
     }
}
